





























import { PageBase } from "@/core/models/shared";
import { RegistroOperacionalService } from "@/core/services/operacional";
import { AlertSimpleErr } from "@/core/services/shared/AlertService";
import { Component } from "vue-property-decorator";

@Component
export default class RelatorioAgendaFuncionario extends PageBase{
    
    loading: boolean = false;
    lista: any[] = [];
    service: RegistroOperacionalService = new RegistroOperacionalService();

    filtro: any = {
        roNumero: null,
        funcionarioId: null,
        clienteId: null,
        embarcacaoId: null
    }

    created() {
        this.filtro = {
            roNumero: this.$route.query.roNumero,
            funcionarioId: this.$route.query.funcionarioId,
            clienteId: this.$route.query.clienteId,
            embarcacaoId: this.$route.query.embarcacaoId
        }
    }

    mounted() {
        this.Carregar();
    }
    
    Carregar(){
        this.loading = true
        this.service.AgendaFuncionario(this.filtro.roNumero, this.filtro.funcionarioId, this.filtro.clienteId, this.filtro.embarcacaoId).then(
            res => {
                this.lista = res.data
            },
            err => AlertSimpleErr("Aviso!", err)
        ).finally(() => {
            this.loading = false;
        })
    }
}
